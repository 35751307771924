import { reactive, watch, ref } from 'vue'
import moment from 'moment'
import { Moment } from 'moment'
import { PaginationAPIRes } from '@/views/Payment/types'
import { DealerPayoutOverviewData } from "@/views/Payment/Controlling/types"
import * as API from "@/API/payment"
import { useStore } from 'vuex'
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal"
import useWait from '@/utils/payment/useWait';
import { useRouter } from 'vue-router';
import { downloadFromStream } from '@/utils/payment/downloadFile'
import { ApproveData } from '@/views/Payment/types'
import { message } from 'ant-design-vue';

type SearchParams = {
  payoutYear: Moment | null;
  payoutMonth: Moment | null;
  entity?: string;
  bu?: string;
  status?: string;
}

const useDealerPayoutOverview = () => {
  const router = useRouter()

  const searchParams = reactive<SearchParams>({
    payoutYear: null,
    payoutMonth: null,
    entity: undefined,
    bu: undefined,
    status: undefined
  })

  const payoutStatuses = ref<string[]>()

  const wait = useWait()

  watch(() => searchParams.payoutYear, (value) => {
    if (value) {
      searchParams.payoutMonth?.year(value.year())
    }
  })

  const pagination = reactive({
    current: 1,
    pageSize: 10,
    total: 0
  })

  const tableData = ref<DealerPayoutOverviewData[]>()

  const loadData = () => {
    const params = {
      year: parseInt(searchParams.payoutYear!.format('YYYY')),
      month: searchParams.payoutMonth ? parseInt(searchParams.payoutMonth.format('M')) : 0,
      entity: searchParams.entity ? searchParams.entity : null,
      bu: searchParams.bu ? searchParams.bu : null,
      status: searchParams.status ? searchParams.status : 'all',
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      userName: 'Dealer User'
    }
    API.getDealerPayoutOverviewList(params).then((res: PaginationAPIRes<DealerPayoutOverviewData>) => {
      tableData.value = res.content
      pagination.total = res.totalElements
    })
  }

  watch(() => [pagination.current, pagination.pageSize], () => {
    loadData()
  })

  const columns = [
    { title: "Payout Round", dataIndex: "payoutRound", slots: { customRender: 'payoutRound' },width: 180},
    { title: "Payout Code",className:"column-no-wrap", dataIndex: "payoutCode", slots: { customRender: 'payoutCode' }, key: "payoutCode", width: 160},
    { title: "Payout Amount w/o VAT", dataIndex: "payoutAmountWoVat",  width: 160, align: 'right', customRender: useThousandsAndTwoDecimal()},
    { title: "Payout Amount w VAT", dataIndex: "payoutAmountWVat",  width: 190, align: 'right', customRender: useThousandsAndTwoDecimal()},
    { title: 'Payout Status', dataIndex: "status", width: 120, align: 'center'},
    { title: "Last Update Date", dataIndex: "updatedAtCast", width: 150, align: 'center'},
    { title: "Approval Path", fixed: 'right', align: 'left', width: 140, slots: { customRender: "approvalPath"}}
  ] 

  const doSearch = () => {
    wait.then(() => {
      pagination.current = 1
      loadData()
    })
  }

  API.getDealerPayoutCondition().then((res) => {
    searchParams.payoutYear = moment().year(res.year)
    payoutStatuses.value = res.dealerStatusList

    if (wait.toRun) {
      wait.toRun()
    }
  })

  const doReset = () => {
    API.getDealerPayoutCondition().then((res) => {
      searchParams.payoutYear = moment().year(res.year)
      searchParams.payoutMonth =  null
      searchParams.entity = undefined,
      searchParams.bu = undefined,
      searchParams.status = undefined
    }).then(() => doSearch())
  }

  const roundClick = (record: DealerPayoutOverviewData) => {
    router.push({ path: `/paymentControlling/dealerRoundSummary/${record.payoutRound}` })
  }

  const downloadPayoutCodeFile = (payoutCode: string) => {
    API.downloadPendingDealerPayoutCodeFile(payoutCode).then((res: any) => {
      downloadFromStream(res)
    })
  }
  
  const approveList = ref<ApproveData[]>()
  const approveVisible = ref(false)
  const approveClick = (record: any) => {
    approveList.value = []
    // API.getApprovalPackage(record.id).then((res: any) => {
    //   if (res.code === '0') {
    //     approveList.value = res.data.nodeList
    //     approveVisible.value = true
    //   } else {
    //     message.error(res.message)
    //   }
    // })

    const params = {
      approvalType: "PayoutApproval",
      payoutCodeId: record.id,
      payoutType: "DEALER"
    }
    API.getApprovalPackageByCreated(params).then((res: any) => {
        if (res.code === '0') {
            approveList.value = res.data.map((item: any) => item.nodeList)
            approveVisible.value = true
        } else {
            message.error(res.message)
        }
    })
  }

  return {
    payoutStatuses,
    searchParams,
    doReset,
    columns,
    tableData,
    pagination,
    doSearch,
    roundClick,
    downloadPayoutCodeFile,
    approveVisible,
    approveClick,
    approveList
  }
}

export default useDealerPayoutOverview