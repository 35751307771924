
import { defineComponent } from 'vue';
import TableWrapper from '@/views/Payment/components/TableWrapper.vue';
import YearPicker from '@/views/Payment/components/YearPicker.vue';
import useDealerPayoutOverview from '@/hooks/payment/controlling/Dealer/DealerPaymentOverview/useDealerPayoutOverview';
import { elementSize } from '@/utils';
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat';
import ApproveSteps from '@/views/Payment/components/ApproveSteps.vue';
import BuSelector from '@/views/Payment/components/BuSelector.vue';
import EntitySelector from '@/views/Payment/components/EntitySelector.vue';

export default defineComponent({
    name: 'paymentTracking',
    components: {
        YearPicker,
        TableWrapper,
        ApproveSteps,
        BuSelector,
        EntitySelector,
    },
    props: {},
    setup() {
        const {
            payoutStatuses,
            searchParams,
            doReset,
            columns,
            tableData,
            pagination,
            doSearch,
            roundClick,
            downloadPayoutCodeFile,
            approveVisible,
            approveClick,
            approveList,
        } = useDealerPayoutOverview();

        doSearch();

        const tableSize = elementSize('.ant-table-wrapper');

        return {
            payoutStatuses,
            searchParams,
            doReset,
            tableData,
            columns,
            pagination,
            doSearch,
            tableSize,
            toThousandsAndTwoDecimal,
            roundClick,
            downloadPayoutCodeFile,
            approveVisible,
            approveClick,
            approveList,
        };
    },
});
